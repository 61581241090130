import React from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import PlaceholderAvatar from '../static/placeholder-avatar.png'
import { thumbnailUrl } from 'utils/thumbnails'

const useStyle = makeStyles({
  root: {
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 12,
    height: 44,
    fontWeight: 'bold',
  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: 20,
    marginRight: 10,
  },
})

function VolunteerListItem({ userId, remove }) {
  const classes = useStyle()

  useFirestoreConnect([
    {
      collection: 'publicUserProfiles',
      doc: userId,
    },
  ])

  const user = useSelector((state) => state.firestore.data.publicUserProfiles?.[userId])
  const avatarUrl = user?.avatarUrl ? thumbnailUrl(user?.avatarUrl, 200) : PlaceholderAvatar

  return (
    isLoaded(user) && (
      <Grid container alignItems='center' justifyContent='space-between' className={classes.root}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img className={classes.avatar} src={avatarUrl} alt='' />
          {user.name}
        </div>
        <Grid item>
          <IconButton size='small' onClick={remove}>
            <CloseIcon fontSize='small' />
          </IconButton>
        </Grid>
      </Grid>
    )
  )
}

export default VolunteerListItem
