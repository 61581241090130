import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFirebase, useFirestore, useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Divider,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import styles from './PostPage.styles'
import {
  Description as DescriptionIcon,
  Image as ImageIcon,
  YouTube as VideoIcon,
  Audiotrack as AudioIcon,
  SettingsInputAntenna as PodcastIcon,
  CloudUpload as UploadIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { InstantSearch, connectAutoComplete, Configure } from 'react-instantsearch-dom'
import searchClient, { getIndexName } from '../../../../utils/algolia'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import PinnedDialog from '../../../../components/PinnedDialog'
import DeleteContentDialog from '../../../../components/DeleteContentDialog'
import PhotoPreview from '../../../../components/PhotoPreview'
import VideoPreview from '../../../../components/VideoPreview'
import AddVideoDialog from '../../../../components/AddVideoDialog'
import AddContentButton from '../../../../components/AddContentButton'
import ResponsibleListItem from '../../../../components/ResponsibleListItem'
import CollaboratorListItem from '../../../../components/CollaboratorListItem'
import { UserIsAuthenticated } from '../../../../utils/router'
import { POSTS_PATH } from '../../../../constants/paths'
import { compose } from 'redux'
import { checkImageDimensionAndDisplayInfoMessage } from 'utils/image'
import { getResponsibleAutocompleteComponent } from 'components/CustomAutocomplete'

const usersIndexName = getIndexName('users')
const contentIndexName = getIndexName('content')

function PostPage() {
  const { organizationId, postId } = useParams()
  const snackbar = useSnackbar()
  const firestore = useFirestore()
  const firebase = useFirebase()
  const history = useHistory()
  const postStoreAs = `organizationPosts${organizationId}${postId}`
  const volunteersStoreAs = `organization${organizationId}Volunteers`
  const collaboratorsStoreAs = `organziation${organizationId}Collaborators`
  const [loading, setLoading] = useState(false)
  const [mediaType, setMediaType] = useState(undefined)
  const [pinnedDialogOpen, setPinnedDialogOpen] = useState(false)
  const [pinnedTitle, setPinnedTitle] = useState('')
  const [deletePhotosOpen, setDeletePhotosOpen] = useState(false)
  const [deleteAudioOpen, setDeleteAudioOpen] = useState(false)
  const [deletePostOpen, setDeletePostOpen] = useState(false)
  const [videoDialogOpen, setVideoDialogOpen] = useState(false)
  const [youtubeId, setYoutubeId] = useState('')
  const [uploadQueue, setUploadQueue] = useState([])
  const [uploading, setUploading] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false)

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Attach posts listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'posts',
          doc: postId,
        },
      ],
      storeAs: postStoreAs,
    },
  ])

  // Attach volunteers listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'volunteers',
          where: ['status', '==', 'volunteer'],
        },
      ],
      storeAs: volunteersStoreAs,
    },
  ])

  // Attach collaborators listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'posts',
          doc: postId,
          subcollections: [
            {
              collection: 'collaborators',
            },
          ],
        },
      ],
      storeAs: collaboratorsStoreAs,
    },
  ])

  const storagePath = `uploads/organizations/${organizationId}/posts`

  const post = useSelector((state) => state.firestore.data[postStoreAs])
  const volunteers = useSelector((state) => state.firestore.ordered[volunteersStoreAs])
  const collaborators = useSelector((state) => state.firestore.ordered[collaboratorsStoreAs])
  const profile = useSelector((state) => state.firebase.profile)

  if (isLoaded(profile)) {
    var privileged =
      profile?.isSuperAdmin ||
      profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
      profile?.organizations?.[organizationId].owner
  }

  const { control, handleSubmit, errors, setValue, getValues, reset, register } = useForm({
    defaultValues: {
      title: '',
      body: '',
      photos: [],
      video: null,
      status: 'published',
      isPinned: false,
      responsibleVolunteers: [],
      collaborators: [],
    },
  })

  const {
    fields: photoFields,
    append: appendPhoto,
    remove: removePhoto,
    move: movePhoto,
  } = useFieldArray({
    control,
    name: 'photos',
  })

  const {
    fields: responsibleFields,
    append: appendResponsible,
    remove: removeResponsible,
  } = useFieldArray({
    control,
    name: 'responsibleVolunteers',
  })

  const {
    fields: collaboratorFields,
    append: appendCollaborator,
    remove: removeCollaborator,
  } = useFieldArray({
    control,
    keyName: 'key',
    name: 'collaborators',
  })

  const ResponsibleAutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'user-autocomplete',
    noOptionsText: 'No matching user found',
    textFieldPlaceholder: 'Select a volunteer',
    onChange: (e, value) => (value?.objectID ? appendResponsible(value.objectID) : null),
    setValueToCurrentRefinement: false,
  })

  const CollaboratorAutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'collaborator-autocomplete',
    noOptionsText: 'No matching organization found',
    textFieldPlaceholder: 'Select an organization',
    onChange: (e, value) => (value?.objectID ? appendCollaborator({ id: value.objectID }) : null),
    setValueToCurrentRefinement: false,
  })

  const CollaboratorAutocomplete = connectAutoComplete(CollaboratorAutocompleteComponent)
  const ResponsibleAutocomplete = connectAutoComplete(ResponsibleAutocompleteComponent)

  useEffect(() => {
    register({ name: 'video' })
    register({ name: 'audio' })
  }, [register])

  useEffect(() => {
    if (post && postId !== 'new') {
      reset({
        ...post,
        photos: post?.media?.photoUrls,
        video: post?.media?.youtubeId,
        audio: post?.media?.audioUrl,
        collaborators: collaborators,
      })
      setMediaType(post?.media?.type)
    }
  }, [post, postId, reset, appendPhoto, collaborators])

  // Set mediaType = '' if there is no media in the form
  useEffect(() => {
    if (!uploading && photoFields.length === 0 && !getValues().video && !getValues().audio) {
      setMediaType(undefined)
    }
  }, [getValues, mediaType, photoFields, uploading])

  // Update the previews
  useEffect(() => {
    setYoutubeId(getValues().video)
  }, [mediaType, getValues])

  // If there are image files in uploadQueue, upload them!
  useEffect(() => {
    const uploadImage = async (file) => {
      try {
        setUploadingImage(true)
        const response = await firebase.uploadFile(storagePath, file, '', { name: uuid() })
        const url = await response.uploadTaskSnapshot.ref.getDownloadURL()
        appendPhoto(url)
      } catch (error) {
        console.log(error)
      }
      setUploadingImage(false)
      setUploading(false)
    }

    if (uploadQueue.length > 0 && !uploadingImage) {
      uploadImage(uploadQueue[0])
      setUploadQueue(uploadQueue.slice(1))
    }
  }, [uploadQueue, uploadingImage, storagePath, firebase, appendPhoto])

  if (loading || !isLoaded(post)) {
    return <LoadingSpinner />
  }

  const handleImageUpload = (e) => {
    const fileExts = ['jpg', 'jpeg', 'gif', 'png']
    const files = e.target.files

    for (const file of files) {
      if (!fileExts.includes(file.name.split('.').pop())) {
        window.alert(
          'Please upload an image file in one of the accepted formats (.jpg, .jpeg, .gif, .png)',
        )
        return
      }

      checkImageDimensionAndDisplayInfoMessage(file, snackbar)
    }

    setUploading(true)
    setMediaType('photos')
    setUploadQueue((u) => {
      return [...u, ...files]
    })
  }

  const addVideoUrl = (id) => {
    setMediaType('video')
    setVideoDialogOpen(false)
    setValue('video', id)
  }

  const handleAudioUpload = async (e) => {
    const fileExts = ['mp3', 'm4a', 'aac']
    const file = e.target.files[0]

    if (!fileExts.includes(file.name.split('.').pop())) {
      window.alert('Please upload an audio file in one of the accepted formats (.mp3, .m4a, .aac)')
      return
    }

    setUploading(true)
    setMediaType('audio')
    try {
      const response = await firebase.uploadFile(storagePath, file, '', { name: uuid() })
      const url = await response.uploadTaskSnapshot.ref.getDownloadURL()
      setValue('audio', url)
      setUploading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // Create the 'media' object for the database
  const addMediaObjToData = (data) => {
    let updatedData = data

    if (mediaType) {
      const media = { type: mediaType }
      if (data.photos && mediaType === 'photos') {
        media.photoUrls = [...data.photos]
      } else if (data.video && mediaType === 'video') {
        media.youtubeId = data.video
      } else if (data.audio && mediaType === 'audio') {
        media.audioUrl = data.audio
      }
      updatedData = { ...updatedData, media }
    }

    delete updatedData.photos
    delete updatedData.video
    delete updatedData.audio

    return updatedData
  }

  const updateCollaborators = (newCollaborators, collaborators) => {
    const added = _.difference(_.map(newCollaborators, 'id'), _.map(collaborators, 'id'))
    const removed = _.difference(_.map(collaborators, 'id'), _.map(newCollaborators, 'id'))

    if (added.length === 0 && removed.length === 0) {
      return
    }

    const collectionRef = firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('posts')
      .doc(postId)
      .collection('collaborators')

    // Delete removed collaborators
    removed.forEach((id) => {
      collectionRef.doc(id).delete()
    })

    // Add new collaborators
    added.forEach((id) => {
      collectionRef.doc(id).set({ status: 'pending' })
    })
  }

  const onSubmit = async (data) => {
    setLoading(true)

    // Add the 'media' map/object to the data model
    const dataWithMedia = addMediaObjToData(data)

    // Update collaborators subcollection and delete from data
    updateCollaborators(data.collaborators, collaborators)

    delete dataWithMedia.collaborators

    if (postId === 'new') {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then((organization) => {
          firestore
            .collection('organizations')
            .doc(organizationId)
            .collection('posts')
            .add({
              ...dataWithMedia,
              organization: {
                logoUrl: organization.data().logoUrl,
                name: organization.data().name,
                status: organization.data().status,
              },
              numComments: 0,
              numLikes: 0,
              status: 'published',
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              setLoading(false)
              snackbar.showMessage('Changes successfully saved')
              history.push(POSTS_PATH(organizationId))
            })
            .catch((err) => {
              setLoading(false)
              console.error('Error:', err) // eslint-disable-line no-console
              return Promise.reject(err)
            })
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    } else {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .collection('posts')
        .doc(postId)
        .update({
          ...dataWithMedia,
          status: 'published',
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(POSTS_PATH(organizationId))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })

      // If the media map was previously set in the database, but media was removed, delete the field.
      if (!dataWithMedia?.media) {
        firestore
          .collection('organizations')
          .doc(organizationId)
          .collection('posts')
          .doc(postId)
          .update({
            media: firebase.firestore.FieldValue.delete(),
          })
      }
    }
  }

  const deletePost = () => {
    if (postId !== 'new') {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .collection('posts')
        .doc(postId)
        .update({ status: 'deleted' })
        .then(() => {
          history.push(POSTS_PATH(organizationId))
        })
        .catch((err) => {
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    }
  }

  // Dialog when pinning post
  const handlePinnedDialogCancel = () => {
    setValue('isPinned', false)
    setPinnedDialogOpen(false)
  }

  const handlePinnedDialogAccept = () => setPinnedDialogOpen(false)

  const handlePinnedState = ([e]) => {
    if (e.target.checked) {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then(function (doc) {
          if (doc.data()?.pinnedContent && doc.data()?.pinnedContent?.id !== postId) {
            setPinnedTitle(doc.data().pinnedContent.title)
            setPinnedDialogOpen(true)
          }
        })
    }
    return e.target.checked
  }

  return (
    <>
      <PinnedDialog
        open={pinnedDialogOpen}
        handleCancel={handlePinnedDialogCancel}
        handleAccept={handlePinnedDialogAccept}
        title={pinnedTitle}
      />
      <DeleteContentDialog
        open={deletePostOpen}
        type='post'
        handleCancel={() => setDeletePostOpen(false)}
        handleAccept={() => deletePost()}
        infoText='This post will be marked as deleted. You can republish it if you need to.'
      />
      <DeleteContentDialog
        open={deletePhotosOpen}
        type='all photos'
        handleCancel={() => setDeletePhotosOpen(false)}
        handleAccept={() => {
          removePhoto()
          setDeletePhotosOpen(false)
        }}
      />
      <DeleteContentDialog
        open={deleteAudioOpen}
        type='audio file'
        handleCancel={() => setDeleteAudioOpen(false)}
        handleAccept={() => {
          setValue('audio', null)
          setMediaType(undefined)
          setDeleteAudioOpen(false)
        }}
      />
      <AddVideoDialog
        open={videoDialogOpen}
        handleCancel={() => setVideoDialogOpen(false)}
        handleAccept={addVideoUrl}
      />

      <Input
        id='photo'
        type='file'
        accept='image/*'
        inputProps={{ multiple: true }}
        onChange={handleImageUpload}
        style={{ display: 'none' }}
      />
      <Input type='text' name='video' style={{ display: 'none' }} />
      <Input type='text' name='audio' style={{ display: 'none' }} />
      <Input
        onChange={handleAudioUpload}
        type='file'
        accept='audio/*'
        id='audio'
        style={{ display: 'none' }}
      />

      <Container className={classes.root} maxWidth='md'>
        <Card className={classes.card}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent className={classes.cardContent}>
              <h2 className={classes.heading}>{postId === 'new' ? 'New post' : 'Edit post'}</h2>
              <Grid container spacing={3} direction='row' justifyContent='flex-start'>
                <Grid className={classes.inputContainer} item xs={12}>
                  <Controller
                    rules={{ required: 'This field is required' }}
                    as={
                      <TextField
                        fullWidth
                        placeholder='Name your post'
                        label='Title'
                        type='text'
                        variant='outlined'
                        error={errors.title !== undefined}
                        helperText={errors?.title?.message}
                      />
                    }
                    name='title'
                    control={control}
                  />
                </Grid>

                <Grid className={classes.inputContainer} item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        placeholder='The content of your post...'
                        multiline
                        minRows={9}
                        label='Text'
                        type='text'
                        variant='outlined'
                      />
                    }
                    name='body'
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.inputContainer} container item xs={12}>
                <Grid item xs={12}>
                  <h4 className={classes.h4}>Add media</h4>
                  <Typography className={classes.p}>
                    You can add one media type to your post. If you want to change the media type,
                    you need to delete the added media first.
                  </Typography>
                </Grid>

                {/* Photo previews */}
                {mediaType === 'photos' && (photoFields.length > 0 || uploadingImage) && (
                  <Grid item xs={12}>
                    <h5>Preview</h5>
                    <div className={classes.mediaContainer}>
                      {photoFields.map((field, index) => (
                        <div key={field.id}>
                          <PhotoPreview
                            src={field.value}
                            move={movePhoto}
                            arrayLength={photoFields.length}
                            i={index}
                            deleteFunc={() => removePhoto(index)}
                          />
                          <Controller
                            as={<input type='text' />}
                            defaultValue={field.value}
                            name={`photos[${index}]`}
                            control={control}
                            style={{ display: 'none' }}
                          />
                        </div>
                      ))}
                      {uploadingImage && (
                        <PhotoPreview desc={`Uploading ${uploadQueue.length + 1} file(s)...`} />
                      )}
                    </div>
                    <InputLabel className={classes.smallMediaLabel} htmlFor='photo'>
                      <Button
                        className={`${classes.addMorePhotosButton} ${classes.smallMediaButton}`}
                        color='primary'
                        component='span'
                      >
                        Add Photos
                        <UploadIcon />
                      </Button>
                      {errors?.photo && (
                        <FormHelperText className={classes.center} error>
                          {errors?.photo?.message}
                        </FormHelperText>
                      )}
                    </InputLabel>
                    <Button
                      onClick={() => setDeletePhotosOpen(true)}
                      className={`${classes.removeButton} ${classes.smallMediaButton}`}
                    >
                      Remove all photos
                    </Button>
                  </Grid>
                )}

                {/* Youtube video preview */}
                {mediaType === 'video' && (
                  <>
                    <h5>Preview</h5>
                    <VideoPreview videoId={youtubeId} />
                    <Button
                      onClick={() => {
                        setValue('video', '')
                        setMediaType(undefined)
                      }}
                      className={`${classes.removeButton} ${classes.smallMediaButton}`}
                      variant='outlined'
                    >
                      Remove video
                    </Button>
                  </>
                )}

                {/* Audio file preview */}
                {mediaType === 'audio' && (
                  <>
                    {uploading ? (
                      <div className={classes.audioPreview}>
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        <div className={classes.audioPreview}>
                          <Grid container alignItems='center'>
                            <AudioIcon className={classes.mr1} />
                            <h5>Audio file</h5>
                          </Grid>
                        </div>
                        <Button
                          onClick={() => setDeleteAudioOpen(true)}
                          className={`${classes.removeButton} ${classes.smallMediaButton}`}
                          variant='outlined'
                        >
                          Remove audio file
                        </Button>
                      </>
                    )}
                  </>
                )}

                {/* Add media buttons, if there is no media */}
                {!mediaType && (
                  <Grid container className={classes.mediaContainer}>
                    <InputLabel htmlFor='photo'>
                      <AddContentButton icon={<ImageIcon />} label='Photos' />
                      {errors?.photo && (
                        <FormHelperText className={classes.center} error>
                          {errors?.photo?.message}
                        </FormHelperText>
                      )}
                    </InputLabel>

                    <AddContentButton
                      onClick={() => setVideoDialogOpen(true)}
                      icon={<VideoIcon />}
                      label='Video'
                    />

                    <InputLabel htmlFor='audio'>
                      <AddContentButton icon={<PodcastIcon />} label='Podcast' />
                      {errors?.audio && (
                        <FormHelperText className={classes.center} error>
                          {errors?.audio?.message}
                        </FormHelperText>
                      )}
                    </InputLabel>
                  </Grid>
                )}
              </Grid>

              {privileged && (
                <>
                  <Grid item xs={12}>
                    <h4 className={classes.h4}>Pinned</h4>
                    <Typography className={classes.p}>
                      You can pin one post, event, collection or open opportunity to the top of the
                      page.
                    </Typography>
                    <Controller
                      as={<Switch color='primary' />}
                      name='isPinned'
                      onChange={handlePinnedState}
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h4 className={classes.h4}>Responsible</h4>
                    <Typography className={classes.p}>
                      Assign responsibility to a volunteer to give them access to edit this content.
                    </Typography>
                    <Grid item xs={6} className={classes.inputContainer}>
                      {isLoaded(volunteers) && (
                        <InstantSearch searchClient={searchClient} indexName={usersIndexName}>
                          <Configure
                            filters={
                              !volunteers || volunteers.length === responsibleFields.length
                                ? 'ObjectID:ReturnNothing'
                                : volunteers
                                    .filter((v) => !responsibleFields.some((f) => f.value === v.id))
                                    .map((v) => `objectID:${v.id}`)
                                    .join(' OR ')
                            }
                          />
                          <ResponsibleAutocomplete />
                        </InstantSearch>
                      )}
                      <div className={classes.inputContainer}>
                        {responsibleFields.map((item, index) => (
                          <div key={item.id}>
                            <ResponsibleListItem
                              userId={item.value}
                              remove={() => removeResponsible(index)}
                            />
                            <Controller
                              as={<input type='text' />}
                              name={`responsibleVolunteers[${index}]`}
                              defaultValue={item.value}
                              control={control}
                              style={{ display: 'none' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <h4 className={classes.h4}>Collaboration</h4>
                    <Typography className={classes.p}>
                      Invite another student organisation to collaborate on content creation. A
                      co-creator will be listed as co-author of the content and the content will
                      show up on their page and feed, but they can’t edit the content.
                    </Typography>
                    <Grid item xs={6} className={classes.inputContainer}>
                      <InstantSearch searchClient={searchClient} indexName={contentIndexName}>
                        <Configure
                          filters={
                            `contentType:organization AND NOT objectID:${organizationId}` +
                            collaboratorFields.map((c) => ` AND NOT objectID:${c.id}`).join('')
                          }
                        />
                        <CollaboratorAutocomplete />
                      </InstantSearch>
                      <div className={classes.inputContainer}>
                        {collaboratorFields.map((item, index) => (
                          <div key={item.key}>
                            <CollaboratorListItem
                              organizationId={item.id}
                              status={item.status}
                              remove={() => removeCollaborator(index)}
                            />
                            <Controller
                              as={<input type='text' />}
                              name={`collaborators[${index}].id`}
                              defaultValue={item.id}
                              control={control}
                              style={{ display: 'none' }}
                            />
                            <Controller
                              as={<input type='text' />}
                              name={`collaborators[${index}].status`}
                              defaultValue={item.status}
                              control={control}
                              style={{ display: 'none' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}

              <Divider className={classes.divider} />
            </CardContent>
            <CardActions className={classes.cardActions}>
              {privileged && post?.status !== 'deleted' && (
                <Button
                  onClick={() => setDeletePostOpen(true)}
                  className={classes.deleteButton}
                  variant='outlined'
                  disabled={loading || uploading}
                  size='large'
                >
                  Delete post
                </Button>
              )}
              <div />
              <Button
                className={classes.saveButton}
                variant='contained'
                type='submit'
                disabled={loading || uploading}
                size='large'
                endIcon={<DescriptionIcon />}
              >
                {post?.status === 'published' ? 'Save changes' : 'Publish'}
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(PostPage))
