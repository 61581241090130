import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFirebase, useFirestore, useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import _ from 'lodash'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Container,
  Divider,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Select,
  Switch,
  Typography,
} from '@material-ui/core'
import styles from './CollectionPage.styles'
import {
  Description as DescriptionIcon,
  Event as EventIcon,
  Description as PostIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { InstantSearch, connectAutoComplete, Configure } from 'react-instantsearch-dom'
import searchClient, { getIndexName } from '../../../../utils/algolia'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import PinnedDialog from '../../../../components/PinnedDialog'
import ContentPreview from '../../../../components/ContentPreview'
import AddContentDialog from '../../../../components/AddContentDialog'
import AddContentButton from '../../../../components/AddContentButton'
import DeleteContentDialog from '../../../../components/DeleteContentDialog'
import ResponsibleListItem from '../../../../components/ResponsibleListItem'
import CollaboratorListItem from '../../../../components/CollaboratorListItem'
import { UserIsAuthenticated } from '../../../../utils/router'
import { COLLECTIONS_PATH } from '../../../../constants/paths'
import { compose } from 'redux'
import { usePrivileged } from '../../../../utils/profile'
import { getResponsibleAutocompleteComponent } from 'components/CustomAutocomplete'

const usersIndexName = getIndexName('users')
const contentIndexName = getIndexName('content')

function CollectionPage() {
  const { organizationId, collectionId } = useParams()
  const snackbar = useSnackbar()
  const firestore = useFirestore()
  const firebase = useFirebase()
  const history = useHistory()
  const storeAs = `organizationCollections${organizationId}${collectionId}`
  const volunteersStoreAs = `organization${organizationId}Volunteers`
  const collaboratorsStoreAs = `organziation${organizationId}Collaborators`
  const [loading, setLoading] = useState(false)
  const [contentDialogOpen, setContentDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [contentType, setContentType] = useState(undefined)
  const [pinnedDialogOpen, setPinnedDialogOpen] = useState(false)
  const [pinnedTitle, setPinnedTitle] = useState('')

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Attach collections listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'collections',
          doc: collectionId,
        },
      ],
      storeAs: storeAs,
    },
  ])

  // Attach volunteers listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'volunteers',
          where: ['status', '==', 'volunteer'],
        },
      ],
      storeAs: volunteersStoreAs,
    },
  ])

  // Attach collaborators listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'collections',
          doc: collectionId,
          subcollections: [
            {
              collection: 'collaborators',
            },
          ],
        },
      ],
      storeAs: collaboratorsStoreAs,
    },
  ])

  const collection = useSelector((state) => state.firestore.data[storeAs])
  const volunteers = useSelector((state) => state.firestore.ordered[volunteersStoreAs])
  const collaborators = useSelector((state) => state.firestore.ordered[collaboratorsStoreAs])

  const privileged = usePrivileged(organizationId)

  const { control, handleSubmit, setValue, getValues, errors, reset, register } = useForm({
    defaultValues: {
      title: '',
      description: '',
      content: [],
      status: 'published',
      isPinned: false,
      responsibleVolunteers: [],
      collaborators: [],
    },
  })

  const { fields, move, remove } = useFieldArray({
    control,
    name: 'content',
    keyName: 'fieldId',
  })

  const {
    fields: responsibleFields,
    append: appendResponsible,
    remove: removeResponsible,
  } = useFieldArray({
    control,
    name: 'responsibleVolunteers',
  })

  const {
    fields: collaboratorFields,
    append: appendCollaborator,
    remove: removeCollaborator,
  } = useFieldArray({
    control,
    keyName: 'key',
    name: 'collaborators',
  })

  const ResponsibleAutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'user-autocomplete',
    noOptionsText: 'No matching user found',
    textFieldPlaceholder: 'Select a volunteer',
    onChange: (e, value) => (value?.objectID ? appendResponsible(value.objectID) : null),
    setValueToCurrentRefinement: false,
  })

  const CollaboratorAutocompleteComponent = getResponsibleAutocompleteComponent({
    id: 'collaborator-autocomplete',
    noOptionsText: 'No matching organization found',
    textFieldPlaceholder: 'Select an organization',
    onChange: (e, value) => (value?.objectID ? appendCollaborator({ id: value.objectID }) : null),
    setValueToCurrentRefinement: false,
  })

  const CollaboratorAutocomplete = connectAutoComplete(CollaboratorAutocompleteComponent)
  const ResponsibleAutocomplete = connectAutoComplete(ResponsibleAutocompleteComponent)

  useEffect(() => {
    if (collection && collectionId !== 'new') reset({ ...collection, collaborators: collaborators })
  }, [collection, collectionId, reset, collaborators])

  if (loading || !isLoaded(collection)) {
    return <LoadingSpinner />
  }

  const updateCollaborators = (newCollaborators, collaborators) => {
    const added = _.difference(_.map(newCollaborators, 'id'), _.map(collaborators, 'id'))
    const removed = _.difference(_.map(collaborators, 'id'), _.map(newCollaborators, 'id'))

    if (added.length === 0 && removed.length === 0) {
      return
    }

    console.log('added:', added)
    console.log('removed:', removed)

    const collectionRef = firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('collections')
      .doc(collectionId)
      .collection('collaborators')

    // Delete removed collaborators
    removed.forEach((id) => {
      collectionRef.doc(id).delete()
    })

    // Add new collaborators
    added.forEach((id) => {
      collectionRef.doc(id).set({ status: 'pending' })
    })
  }

  const onSubmit = (data) => {
    setLoading(true)

    // Update collaborators subcollection and delete from data
    updateCollaborators(data.collaborators, collaborators)
    delete data.collaborators

    if (collectionId === 'new') {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then((organization) => {
          firestore
            .collection('organizations')
            .doc(organizationId)
            .collection('collections')
            .add({
              ...data,
              content: data.content || [],
              organization: {
                logoUrl: organization.data().logoUrl,
                name: organization.data().name,
                status: organization.data().status,
              },
              status: 'published',
            })
            .then(() => {
              setLoading(false)
              snackbar.showMessage('Changes successfully saved')
              history.push(COLLECTIONS_PATH(organizationId))
            })
            .catch((err) => {
              setLoading(false)
              console.error('Error:', err) // eslint-disable-line no-console
              return Promise.reject(err)
            })
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    } else {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .collection('collections')
        .doc(collectionId)
        .update({
          ...data,
          content: data.content || [],
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setLoading(false)
          snackbar.showMessage('Changes successfully saved')
          history.push(COLLECTIONS_PATH(organizationId))
        })
        .catch((err) => {
          setLoading(false)
          console.error('Error:', err) // eslint-disable-line no-console
          return Promise.reject(err)
        })
    }
  }

  // Dialog when pinning event
  const handlePinnedDialogCancel = () => {
    setValue('isPinned', false)
    setPinnedDialogOpen(false)
  }

  const handlePinnedDialogAccept = () => setPinnedDialogOpen(false)

  const handlePinnedState = ([e]) => {
    if (e.target.checked) {
      firestore
        .collection('organizations')
        .doc(organizationId)
        .get()
        .then(function (doc) {
          if (doc.data()?.pinnedContent && doc.data()?.pinnedContent?.id !== collectionId) {
            setPinnedTitle(doc.data().pinnedContent.title)
            setPinnedDialogOpen(true)
          }
        })
    }
    return e.target.checked
  }

  const updateContent = (array) => {
    const updatedCollection = getValues()
    updatedCollection.content = array
    if (updatedCollection.content) {
      reset(updatedCollection)
    }

    setContentDialogOpen(false)
  }

  return (
    <>
      <PinnedDialog
        open={pinnedDialogOpen}
        handleCancel={handlePinnedDialogCancel}
        handleAccept={handlePinnedDialogAccept}
        title={pinnedTitle}
      />
      <AddContentDialog
        open={contentDialogOpen}
        type={contentType === undefined ? 'post' : contentType}
        currentContent={fields}
        organizationId={organizationId}
        handleAccept={updateContent}
      />
      <DeleteContentDialog
        open={deleteDialogOpen}
        type='all content'
        handleCancel={() => setDeleteDialogOpen(false)}
        handleAccept={() => {
          remove()
          setDeleteDialogOpen(false)
        }}
      />
      <Container className={classes.root} maxWidth='md'>
        <Card className={classes.card}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent className={classes.cardContent}>
              <h2 className={classes.heading}>
                {collectionId === 'new' ? 'New collection' : 'Edit collection'}
              </h2>
              <Grid container spacing={3} direction='row' justifyContent='flex-start'>
                <Grid className={classes.inputContainer} item xs={12}>
                  <Controller
                    rules={{ required: 'This field is required' }}
                    as={
                      <TextField
                        fullWidth
                        placeholder='Name your collection'
                        label='Title'
                        type='text'
                        variant='outlined'
                        error={errors.title !== undefined}
                        helperText={errors?.title?.message}
                      />
                    }
                    name='title'
                    control={control}
                  />
                </Grid>

                <Grid className={classes.inputContainer} item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        placeholder='A description of your collection...'
                        multiline
                        minRows={9}
                        label='Text'
                        type='text'
                        variant='outlined'
                      />
                    }
                    name='description'
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid className={classes.container}>
                <h4>Content</h4>
              </Grid>

              <Grid container className={classes.container}>
                <AddContentButton
                  onClick={() => {
                    setContentType('post')
                    setContentDialogOpen(true)
                  }}
                  icon={<PostIcon />}
                  label='Posts'
                />
                <AddContentButton
                  onClick={() => {
                    setContentType('event')
                    setContentDialogOpen(true)
                  }}
                  icon={<EventIcon />}
                  label='Events'
                />
              </Grid>

              {fields.map((item, index) => (
                <div key={item.fieldId}>
                  <input
                    name={`content[${index}].type`}
                    defaultValue={item.type}
                    ref={register()}
                    style={{ display: 'none' }}
                  />
                  <input
                    name={`content[${index}].id`}
                    defaultValue={item.id}
                    ref={register()}
                    style={{ display: 'none' }}
                  />
                  <ContentPreview
                    type={item.type}
                    id={item.id}
                    move={move}
                    deleteFunc={() => remove(index)}
                    arrayLength={fields.length}
                    i={index}
                    organizationId={organizationId}
                  />
                </div>
              ))}

              <Button onClick={() => setDeleteDialogOpen(true)} variant='outlined'>
                Remove all
              </Button>

              <Grid className={classes.inputContainer} item xs={8}>
                <InputLabel className={classes.inputLabel}>Status</InputLabel>
                <Controller
                  as={
                    <Select
                      variant='outlined'
                      className={classes.select}
                      MenuProps={{
                        elevation: 2,
                      }}
                    >
                      <MenuItem className={classes.menuItem} value='published'>
                        Published
                      </MenuItem>
                      <MenuItem value='deleted' className={classes.menuItem}>
                        Deleted
                      </MenuItem>
                    </Select>
                  }
                  label='Status'
                  name='status'
                  rules={{ required: 'This field is required' }}
                  control={control}
                />
              </Grid>

              {privileged && (
                <>
                  <Grid item xs={12}>
                    <h4>Pinned</h4>
                    <p>
                      You can pin one post, event, collection or open opportunity to the top of the
                      page.
                    </p>
                    <Controller
                      as={<Switch color='primary' />}
                      name='isPinned'
                      onChange={handlePinnedState}
                      control={control}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <h4 className={classes.h4}>Responsible</h4>
                    <Typography className={classes.p}>
                      Assign responsibility to a volunteer to give them access to edit this content.
                    </Typography>
                    <Grid item xs={6} className={classes.inputContainer}>
                      {isLoaded(volunteers) && (
                        <InstantSearch searchClient={searchClient} indexName={usersIndexName}>
                          <Configure
                            hitsPerPage={100}
                            filters={
                              !volunteers || volunteers.length === responsibleFields.length
                                ? 'ObjectID:ReturnNothing'
                                : volunteers
                                    .filter((v) => !responsibleFields.some((f) => f.value === v.id))
                                    .map((v) => `objectID:${v.id}`)
                                    .join(' OR ')
                            }
                          />
                          <ResponsibleAutocomplete />
                        </InstantSearch>
                      )}
                      <div className={classes.inputContainer}>
                        {responsibleFields.map((item, index) => (
                          <div key={item.id}>
                            <ResponsibleListItem
                              userId={item.value}
                              remove={() => removeResponsible(index)}
                            />
                            <Controller
                              as={<input type='text' />}
                              name={`responsibleVolunteers[${index}]`}
                              defaultValue={item.value}
                              control={control}
                              style={{ display: 'none' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12}>
                    <h4 className={classes.h4}>Collaboration</h4>
                    <Typography className={classes.p}>
                      Invite another student organisation to collaborate on content creation. A
                      co-creator will be listed as co-author of the content and the content will
                      show up on their page and feed, but they can’t edit the content.
                    </Typography>
                    <Grid item xs={6} className={classes.inputContainer}>
                      <InstantSearch searchClient={searchClient} indexName={contentIndexName}>
                        <Configure
                          hitsPerPage={100}
                          filters={
                            `contentType:organization AND NOT objectID:${organizationId}` +
                            collaboratorFields.map((c) => ` AND NOT objectID:${c.id}`).join('')
                          }
                        />
                        <CollaboratorAutocomplete />
                      </InstantSearch>
                      <div className={classes.inputContainer}>
                        {collaboratorFields.map((item, index) => (
                          <div key={item.key}>
                            <CollaboratorListItem
                              organizationId={item.id}
                              status={item.status}
                              remove={() => removeCollaborator(index)}
                            />
                            <Controller
                              as={<input type='text' />}
                              name={`collaborators[${index}].id`}
                              defaultValue={item.id}
                              control={control}
                              style={{ display: 'none' }}
                            />
                            <Controller
                              as={<input type='text' />}
                              name={`collaborators[${index}].status`}
                              defaultValue={item.status}
                              control={control}
                              style={{ display: 'none' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}

              <Divider className={classes.divider} />
            </CardContent>
            <CardActions className={classes.actions}>
              <Button
                className={classes.saveButton}
                variant='contained'
                color='primary'
                type='submit'
                disabled={loading}
                size='large'
                endIcon={<DescriptionIcon />}
              >
                Publish collection / save changes
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </>
  )
}

export default compose(UserIsAuthenticated(CollectionPage))
