import React from 'react'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

const useStyle = makeStyles({
  root: {
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 12,
    height: 44,
    fontWeight: 'bold',
  },
})

function ResponsibleListItem({ userId, remove }) {
  const classes = useStyle()

  useFirestoreConnect([
    {
      collection: 'publicUserProfiles',
      doc: userId,
    },
  ])

  const user = useSelector((state) => state.firestore.data.publicUserProfiles?.[userId])

  return (
    isLoaded(user) && (
      <Grid container alignItems='center' justifyContent='space-between' className={classes.root}>
        {user.name}
        <IconButton size='small' onClick={remove}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </Grid>
    )
  )
}

export default ResponsibleListItem
