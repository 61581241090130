import React, { useEffect, useState } from 'react'
import { NavLink, matchPath, useLocation } from 'react-router-dom'
import {
  Drawer as MaterialDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core'
import {
  People as PeopleIcon,
  Event as EventIcon,
  Description as PostIcon,
  ListAlt as CollectionIcon,
  ArrowBack as ArrowBackIcon,
  Assessment as AssessmentIcon,
  QuestionAnswer as ChatIcon,
  Assignment as VolunteerJobsIcon,
} from '@material-ui/icons'
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  ORGANIZATIONS_PATH,
  EVENTS_PATH,
  ORGANIZATION_PATH,
  EDIT_ORGANIZATION_PATH,
  POSTS_PATH,
  CHAT_PATH,
  COLLECTIONS_PATH,
  VOLUNTEERS_PATH,
  ROOMBOOKING_PATH,
  VOLUNTEER_JOBS_PATH,
} from '../constants/paths'
import styles from './Drawer.styles'
import _ from 'lodash'

const useStyles = makeStyles(styles)

function Drawer() {
  const classes = useStyles()
  const location = useLocation()

  useFirestoreConnect([{ collection: 'organizations' }])

  const profile = useSelector((state) => state.firebase.profile)
  const auth = useSelector((state) => state.firebase.auth)
  const organizationsState = useSelector((state) => state.firestore.ordered.organizations)

  const match = matchPath(location.pathname, {
    path: ORGANIZATION_PATH(':organizationId'),
    exact: false,
    strict: false,
  })

  // Usage: include the path to exclude the navbar from a given route
  const [ignoreRoute, setIgnoreRoute] = useState(false)

  const ignoredRoutes = [ROOMBOOKING_PATH()]

  useEffect(() => {
    if (
      ignoredRoutes
        .map((x) => '^\\' + x.replace(/\/undefined/g, '\\/[^\\/]*') + '$')
        .reduce((a, b) => a || RegExp(b).test(location.pathname), false)
    )
      setIgnoreRoute(true)
  }, [location])

  if (ignoreRoute) return null

  const organizationId = match?.params?.organizationId
  // Show spinner while projects & profile are loading
  if (
    !isLoaded(organizationsState) ||
    !isLoaded(profile) ||
    !isLoaded(auth) ||
    !organizationId ||
    organizationId === 'new'
  ) {
    return null
  }

  // If owner, admin or volunteer, show org
  const organizationIds = _.keys(_.pickBy(profile?.organizations || {}, { owner: true }))
  organizationIds.push(
    ..._.keys(_.pickBy(profile?.organizations || {}, { volunteerStatus: 'admin' })),
  )
  organizationIds.push(
    ..._.keys(_.pickBy(profile?.organizations || {}, { volunteerStatus: 'volunteer' })),
  )

  const organizations = _.filter(organizationsState, (org) =>
    !profile.isSuperAdmin ? _.includes(organizationIds, org.id) : org,
  )

  const organization = _.find(organizationsState, { id: organizationId })

  const privileged =
    profile?.isSuperAdmin ||
    profile?.organizations?.[organizationId].volunteerStatus === 'admin' ||
    profile?.organizations?.[organizationId].owner

  return (
    <div className={classes.root}>
      <MaterialDrawer
        className={classes.drawer}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {organizations.length > 1 ? (
            <NavLink to={ORGANIZATIONS_PATH}>
              <ListItem className={classes.listItem} button key={ORGANIZATIONS_PATH}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ArrowBackIcon />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  {organization?.name || ''}
                </ListItemText>
              </ListItem>
            </NavLink>
          ) : (
            <ListItem className={classes.listItem} key={ORGANIZATIONS_PATH}>
              <ListItemText className={classes.listItemText}>
                {organization?.name || ''}
              </ListItemText>
            </ListItem>
          )}
          <Divider />
          <NavLink activeClassName={classes.active} exact to={ORGANIZATION_PATH(organizationId)}>
            <ListItem className={classes.listItem} button key={EVENTS_PATH}>
              <ListItemIcon className={classes.listItemIcon}>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>Dashboard</ListItemText>
            </ListItem>
          </NavLink>
          <NavLink
            activeClassName={classes.active}
            exact
            to={EDIT_ORGANIZATION_PATH(organizationId)}
          >
            <ListItem className={classes.listItem} button key={EVENTS_PATH}>
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>Public Profile</ListItemText>
            </ListItem>
          </NavLink>
          <NavLink activeClassName={classes.active} to={EVENTS_PATH(organizationId)}>
            <ListItem className={classes.listItem} button key={EVENTS_PATH}>
              <ListItemIcon className={classes.listItemIcon}>
                <EventIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>Events</ListItemText>
            </ListItem>
          </NavLink>
          <NavLink activeClassName={classes.active} to={POSTS_PATH(organizationId)}>
            <ListItem className={classes.listItem} button key={POSTS_PATH}>
              <ListItemIcon className={classes.listItemIcon}>
                <PostIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>Posts</ListItemText>
            </ListItem>
          </NavLink>
          <NavLink activeClassName={classes.active} to={COLLECTIONS_PATH(organizationId)}>
            <ListItem className={classes.listItem} button key={COLLECTIONS_PATH}>
              <ListItemIcon className={classes.listItemIcon}>
                <CollectionIcon />
              </ListItemIcon>
              <ListItemText className={classes.listItemText}>Collections</ListItemText>
            </ListItem>
          </NavLink>
          {privileged && (
            <>
              <NavLink activeClassName={classes.active} to={CHAT_PATH(organizationId)}>
                <ListItem className={classes.listItem} button key={CHAT_PATH}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <ChatIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listItemText}>Chat</ListItemText>
                </ListItem>
              </NavLink>
              <NavLink activeClassName={classes.active} to={VOLUNTEER_JOBS_PATH(organizationId)}>
                <ListItem className={classes.listItem} button key={VOLUNTEER_JOBS_PATH}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <VolunteerJobsIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listItemText}>Open Opportunities</ListItemText>
                </ListItem>
              </NavLink>
              <NavLink activeClassName={classes.active} to={VOLUNTEERS_PATH(organizationId)}>
                <ListItem className={classes.listItem} button key={VOLUNTEERS_PATH}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listItemText}>Volunteers</ListItemText>
                </ListItem>
              </NavLink>
            </>
          )}
        </List>
      </MaterialDrawer>
    </div>
  )
}

export default Drawer
