import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import React from 'react'
import ReactDOM from 'react-dom'
import p from '../package.json'
import { env } from './config'
import './index.css'
import createStore from './store/createStore'
import getEnv from './utils/getEnv'
import App from './containers/App'

// import * as serviceWorker from './serviceWorker'
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    version: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ___INITIAL_STATE__: any
  }
}

window.version = p.version
window.env = env

// Error handling
// ------------------------------------
Sentry.init({
  dsn: 'https://729b7db1d0d24e37a94e3c50870805df@o406619.ingest.sentry.io/5274424',
  environment: getEnv(),
})
if (getEnv() !== 'development' && getEnv() !== 'production')
  LogRocket.init('rn2opc/cbs-student-life')

// Store Initialization
// ------------------------------------
const initialState = window.___INITIAL_STATE__ || {
  firebase: { authError: null },
}
const store = createStore(initialState)
const routes = require('./routes/index').default(store)

ReactDOM.render(<App store={store} routes={routes} />, document.getElementById('root'))
