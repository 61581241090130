import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import ChatContext from './ChatContext'
import { thumbnailUrl } from '../../utils/thumbnails'

const useStyle = makeStyles({
  root: {
    maxWidth: '50%',
    marginBottom: 20,
    display: 'flex',
  },
  bodyContainer: {
    borderRadius: 12,
    margin: 10,
    marginTop: 0,
    marginBottom: 0,
    whiteSpace: 'break-spaces',
    padding: 10,
    fontSize: 14,
    display: 'table', // keep proper width
  },
  byline: {
    fontSize: 12,
    marginTop: 6,
    display: 'block',
    color: '#707070',
    height: 10,
  },
  leftByline: {
    marginLeft: 10,
    textAlign: 'left',
  },
  rightByline: {
    marginRight: 10,
    textAlign: 'right',
  },
  avatar: {
    width: 36,
    height: 36,
    minWidth: 36,
    minHeight: 36,
    borderRadius: 40,
    alignSelf: 'flex-end',
    marginBottom: 15,
  },
  rightBody: {
    color: '#FFFFFF',
    backgroundColor: '#5050A5',
    borderBottomRightRadius: 4,
    marginLeft: 'auto',
  },
  leftBody: {
    color: '#333333',
    backgroundColor: '#EEEEEE',
    borderBottomLeftRadius: 4,
    marginRight: 'auto',
  },
  groupedBody: {
    marginLeft: 46,
    marginRight: 46,
  },
})

const ChatMessage = ({ message, grouped }) => {
  const classes = useStyle()
  useFirestoreConnect([
    {
      collection: 'publicUserProfiles',
      doc: message.userId,
    },
  ])

  const user = useSelector((state) => state.firestore.data.publicUserProfiles?.[message.userId])
  const organization = useSelector(
    (state) =>
      message.organizationId && state.firestore.data.organizations?.[message.organizationId],
  )

  const avatarUrl = user?.avatarUrl
    ? thumbnailUrl(user?.avatarUrl, 200)
    : require('../../static/placeholder-avatar.png')

  return (
    <div
      className={classes.root}
      style={{
        alignSelf: message.organizationId ? 'flex-end' : 'flex-start',
        marginBottom: grouped ? 2 : undefined,
      }}
    >
      {!grouped && (
        <img
          src={message.organizationId ? organization.logoUrl : avatarUrl}
          alt={message.organizationId ? organization.name : user?.name} // TODO: Fix this when applicable
          className={classes.avatar}
          style={{ order: message.organizationId ? 1 : 0 }}
        />
      )}
      <div>
        {message.context && <ChatContext context={message.context} grouped={grouped} />}
        <div
          className={`${classes.bodyContainer} ${
            message.organizationId ? classes.rightBody : classes.leftBody
          } ${grouped && classes.groupedBody}`}
        >
          {message.body}
        </div>

        {!grouped && (
          <Typography
            className={`${classes.byline} ${
              message.organizationId ? classes.rightByline : classes.leftByline
            }`}
          >
            {message.organizationId && user && isLoaded(user) && `From ${user.name} · `}
            {message.createdAt ? moment(message.createdAt.seconds * 1000).format('HH:mm') : 'Now'}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default ChatMessage
