import React from 'react'
import { useParams } from 'react-router-dom'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'
import DashboardCollaborations from '../../../components/DashboardCollaborations'

function Dashboard() {
  // Relevant parameters
  const { organizationId } = useParams()
  const storeOrganizationsAs = 'organizations'

  useFirestoreConnect([
    {
      collection: 'organizations',
      storeAs: storeOrganizationsAs,
    },
  ])

  const organization = useSelector((state) => state.firestore.data.organizations?.[organizationId])
  const userId = useSelector((state) => state.firebase.auth.uid)
  const profile = useSelector((state) => state.firebase.profile)

  const isOwnerOrAdmin =
    userId === organization?.owner.userId ||
    organization?.admins?.includes(userId) ||
    profile.isSuperAdmin

  if (isOwnerOrAdmin) {
    return <DashboardCollaborations organizationId={organizationId} />
  }
  return <></>
}

export default compose(UserIsAuthenticated(Dashboard))
