export function getImageDimensions(url, callback) {
  const img = document.createElement('img')
  img.onload = function () {
    callback(this.width, this.height)
  }
  img.src = url
}

export function checkImageDimensionAndDisplayInfoMessage(file, snackbar) {
  getImageDimensions(URL.createObjectURL(file), (width, height) => {
    const normalizedAspectRatio = Math.abs(width / height - 16 / 9)
    if (normalizedAspectRatio > 0.05) {
      snackbar.showMessage(
        'The recommended format for photos is 16:9 (e.g. 1280x720px). Detected another format. Your image might be shown incorrectly.',
      )
    }
  })
}
