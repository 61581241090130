export default (theme) => ({
  root: {
    marginLeft: 260,
    marginTop: 30,
    marginBottom: 150,
  },
  card: {
    ...theme.elevation,
    borderRadius: '10px',
  },
  cardContent: {
    padding: theme.spacing(5),
    paddingBottom: 0,
  },
  cardActions: {
    padding: theme.spacing(5),
    justifyContent: 'space-between',
  },
  heading: {
    marginTop: 0,
    marginBottom: 20,
    fontWeight: 'bold',
    color: '#32305D',
  },
  h4: {
    color: '#32305D',
  },
  p: {
    color: '#707070',
    marginBottom: theme.spacing(1),
  },
  inputContainer: {
    marginTop: 15,
  },
  mediaContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
  audioPreview: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
  addMediaButton: {
    marginRight: theme.spacing(4),
    '& .MuiButton-label': {
      justifyContent: 'center',
    },
    width: '100%',
    height: '70px',
    ...theme.elevation,
    '&:hover': {
      ...theme.elevation,
    },
  },
  smallMediaLabel: {
    marginBottom: theme.spacing(3),
    width: '210px',
  },
  smallMediaButton: {
    height: '36px',
    width: '210px',
  },
  addMorePhotosButton: {
    ...theme.elevation,
  },
  removeButton: {
    backgroundColor: 'white',
    border: '1px solid #333333',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  selectMenu: {
    boxShadow: 0,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  collaborator: {
    backgroundColor: '#EEEEEE',
    borderRadius: 5,
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 12,
    height: 44,
    fontWeight: 'bold',
  },
  deleteButton: {
    backgroundColor: 'white',
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    border: `1px solid ${theme.palette.red.main}`,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  saveButton: {
    backgroundColor: theme.palette.green.main,
    color: '#FFFFFF',
    ...theme.elevation,
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.green.hover,
      ...theme.elevation,
    },
  },
  divider: {
    marginTop: 40,
  },
  mr1: {
    marginRight: theme.spacing(1),
  },
})
