import React from 'react'
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Chip,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core/'
import {
  AddCircle as AddCircleIcon,
  Schedule as ScheduleIcon,
  LocationOn as LocationOnIcon,
} from '@material-ui/icons'
import * as moment from 'moment'
import { useSelector } from 'react-redux'
import { EVENT_PATH } from '../../../constants/paths'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ListItemButton from '../../../components/ListItemButton'
import styles from './EventsPage.styles'
import { UserIsAuthenticated } from '../../../utils/router'
import { compose } from 'redux'

const useStyles = makeStyles(styles)

function useEvents() {
  // Get auth from redux state
  const history = useHistory()
  const { organizationId } = useParams()
  const storeAs = `organizationEvents${organizationId}`

  // Attach events listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'events',
        },
      ],
      storeAs: storeAs,
    },
  ])

  const events = useSelector((state) => state.firestore.ordered[storeAs])

  return { events, history, organizationId }
}

function EventsPage() {
  const classes = useStyles()
  const { events, history, organizationId } = useEvents()

  const profile = useSelector((state) => state.firebase.profile)
  const auth = useSelector((state) => state.firebase.auth)

  if (isLoaded(profile)) {
    var privileged =
      profile?.isSuperAdmin ||
      profile?.organizations?.[organizationId]?.volunteerStatus === 'admin' ||
      profile?.organizations?.[organizationId].owner
  }

  // Show spinner while projects are loading
  if (!isLoaded(events)) {
    return <LoadingSpinner />
  }

  const goToEvent = (eventId) => {
    history.push(EVENT_PATH(organizationId, eventId))
  }

  return (
    <>
      <Container className={classes.root} maxWidth='md'>
        <Card>
          <CardHeader
            className={classes.cardHeader}
            title={<h4 className={classes.heading}>Planned events</h4>}
            action={
              privileged && (
                <Button
                  color='primary'
                  className={classes.create}
                  variant='contained'
                  endIcon={<AddCircleIcon />}
                  onClick={() => {
                    goToEvent('new')
                  }}
                >
                  Create Event
                </Button>
              )
            }
          />
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label='Planned events'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCell}>Event</TableCell>
                    <TableCell className={classes.headTableCell}>Date</TableCell>
                    <TableCell className={classes.headTableCell}>Location</TableCell>
                    <TableCell className={classes.headTableCell}>Status</TableCell>
                    <TableCell className={classes.headTableCell} align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(events) &&
                    events
                      .filter((event) => new Date(event.timeBegin.seconds * 1000) > new Date())
                      .map((event) => {
                        return (
                          <TableRow key={event.id}>
                            <TableCell className={classes.tableCell} component='th' scope='row'>
                              {event.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box alignItems='center' display='flex'>
                                <ScheduleIcon className={classes.tableIcon} />
                                {moment(event.timeBegin.seconds * 1000).format(
                                  'HH:mm, D MMMM, YYYY',
                                )}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box alignItems='center' display='flex'>
                                <LocationOnIcon className={classes.tableIcon} />
                                {event.location}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCellSmall}>
                              <Chip
                                size='small'
                                className={classes[event.status]}
                                label={event.status}
                              />
                            </TableCell>
                            <TableCell className={classes.tableCellSmall} align='right'>
                              {(privileged || event?.responsibleVolunteers?.includes(auth.uid)) && (
                                <ListItemButton
                                  label='Edit'
                                  onClick={() => {
                                    goToEvent(event.id)
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={<h4 className={classes.heading}>Past events</h4>}
          />
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label='Planned events'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headTableCell}>Event</TableCell>
                    <TableCell className={classes.headTableCell}>Date</TableCell>
                    <TableCell className={classes.headTableCell}>Location</TableCell>
                    <TableCell className={classes.headTableCell}>Status</TableCell>
                    <TableCell className={classes.headTableCell} align='right' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isEmpty(events) &&
                    events
                      .filter((event) => new Date(event.timeBegin.seconds * 1000) < new Date())
                      .map((event) => {
                        return (
                          <TableRow key={event.id}>
                            <TableCell className={classes.tableCell} component='th' scope='row'>
                              {event.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box alignItems='center' display='flex'>
                                <ScheduleIcon className={classes.tableIcon} />
                                {moment(event.timeBegin.seconds * 1000).format(
                                  'HH:mm, D MMMM, YYYY',
                                )}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Box alignItems='center' display='flex'>
                                <LocationOnIcon className={classes.tableIcon} />
                                {event.location}
                              </Box>
                            </TableCell>
                            <TableCell className={classes.tableCellSmall}>
                              <Chip size='small' className={classes.past} label='Past' />
                            </TableCell>
                            <TableCell className={classes.tableCellSmall} align='right'>
                              <ListItemButton
                                label='Edit'
                                onClick={() => {
                                  goToEvent(event.id)
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}
export default compose(UserIsAuthenticated(EventsPage))
