export default (theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    marginLeft: 260,
  },
  card: {
    padding: theme.spacing(2),
  },
  heading: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'bold',
    color: '#32305D',
  },
  roombookingCard: {
    marginTop: 16,
  },
  image: {
    height: 130,
    paddingTop: 0,
    borderRadius: '5px',
  },
  uploadInput: {
    display: 'none',
  },
  inputContainer: {
    marginTop: 15,
  },
  selectMenu: {
    boxShadow: 0,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveButton: {
    backgroundColor: theme.palette.green.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.green.hover,
    },
  },
  deleteButton: {
    borderColor: theme.palette.red.main,
    color: theme.palette.red.main,
  },
  cancelButton: {
    borderColor: '#B10024',
    color: '#B10024',
  },
  uploadButtonContainer: {
    marginTop: 0,
  },
  uploadButtonLabel: {
    display: 'block',
    margin: '10px 0 0 0',
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  action: {
    alignSelf: 'initial',
    marginTop: 0,
  },
})
