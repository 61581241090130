import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useFirestore, useFirestoreConnect, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'material-ui-snackbar-provider'
import { useForm, Controller } from 'react-hook-form'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Dialog,
  Select,
  MenuItem,
} from '@material-ui/core'
import { Save as SaveIcon, Close as CloseIcon, Forum as ChatIcon } from '@material-ui/icons'
import { CHAT_PATH } from '../../../constants/paths'
import { makeStyles } from '@material-ui/core/styles'
import RemoveVolunteerDialog from './RemoveVolunteerDialog'
import UnsavedChangesDialog from '../../../components/UnsavedChangesDialog'
import { thumbnailUrl } from '../../../utils/thumbnails'

const styles = (theme) => ({
  paper: {
    borderRadius: '10px',
    minWidth: 650,
  },
  card: {
    padding: 24,
  },
  cardHeader: {
    alignItems: 'normal',
  },
  cardContent: {
    paddingTop: 0,
  },
  heading: {
    margin: 0,
    color: '#32305D',
  },
  closeButton: {
    backgroundColor: '#EEEEEE',
  },
  button: {
    margin: '40px 0',
  },
  h4: {
    color: '#32305D',
  },
  p: {
    color: '#707070',
    fontSize: 16,
  },
  subheading: {
    marginTop: 0,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  date: {
    color: '#707070',
  },
  selectPaper: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveButton: {
    color: 'white',
    backgroundColor: theme.palette.green.main,
    marginLeft: 30,
    '&:hover': {
      backgroundColor: theme.palette.green.hover,
    },
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginBottom: 20,
  },
})

function VolunteerInfoDialog({ open, handleClose, userId, user, date }) {
  const { organizationId } = useParams()
  const [loading, setLoading] = useState(false)
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState(false)
  const snackbar = useSnackbar()
  const firestore = useFirestore()
  const history = useHistory()
  const storeAs = `organizations${organizationId}volunteers${userId}`

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  // Attach posts listener
  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organizationId,
      subcollections: [
        {
          collection: 'volunteers',
          doc: userId,
        },
      ],
      storeAs: storeAs,
    },
  ])

  const volunteer = useSelector((state) => state.firestore.data[storeAs])

  const { control, handleSubmit, reset, formState } = useForm()

  useEffect(() => {
    isLoaded(volunteer) && reset(volunteer)
  }, [volunteer, reset])

  const onSubmit = async (data) => {
    setLoading(true)

    firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('volunteers')
      .doc(userId)
      .update({
        ...data,
      })
      .then(() => {
        setLoading(false)
        snackbar.showMessage('Changes successfully saved')
        handleClose()
      })
      .catch((err) => {
        setLoading(false)
        console.error('Error:', err) // eslint-disable-line no-console
        return Promise.reject(err)
      })
  }

  const removeVolunteer = () => {
    firestore
      .collection('organizations')
      .doc(organizationId)
      .collection('volunteers')
      .doc(userId)
      .delete()
      .then(handleClose())
      .catch((err) => {
        console.log('Error:', err) // eslint-disable-line no-console
        return Promise.reject(err)
      })
  }

  const attemptClose = useCallback(() => {
    formState.dirty ? setUnsavedDialogOpen(true) : handleClose()
  }, [formState.dirty, handleClose])

  const closeDialogs = () => {
    reset(volunteer) // Necessary for resetting the dirty state
    setUnsavedDialogOpen(false)
    handleClose()
  }

  return (
    <>
      <RemoveVolunteerDialog
        open={removeDialogOpen}
        handleCancel={() => setRemoveDialogOpen(false)}
        handleAccept={removeVolunteer}
      />
      <UnsavedChangesDialog
        open={unsavedDialogOpen}
        handleCancel={() => setUnsavedDialogOpen(false)}
        handleAccept={closeDialogs}
      />
      <Dialog open={open} onClose={attemptClose} classes={{ paper: classes.paper }}>
        <Card className={classes.card}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader
              className={classes.cardHeader}
              title={
                <>
                  <img
                    className={classes.avatar}
                    src={
                      user?.avatarUrl
                        ? thumbnailUrl(user?.avatarUrl, 200)
                        : require('../../../static/placeholder-avatar.png')
                    }
                    alt=''
                  />
                  <h4 className={classes.heading}>{user?.name}</h4>
                  <p className={classes.p}>{user?.studyProgramme}</p>
                </>
              }
              action={
                <IconButton onClick={attemptClose} className={classes.closeButton}>
                  <CloseIcon color='action' />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <p className={classes.subheading}>Volunteer since</p>
              <p className={classes.date}>{date}</p>
              <Button
                onClick={() =>
                  history.push({ pathname: CHAT_PATH(organizationId), chatUserId: userId })
                }
                className={classes.button}
                variant='contained'
                color='primary'
                endIcon={<ChatIcon />}
              >
                Go to chat
              </Button>
              <h4 className={classes.h4}>User status</h4>
              <Controller
                as={
                  <Select
                    variant='outlined'
                    fullWidth
                    MenuProps={{ classes: { paper: classes.selectPaper } }}
                  >
                    <MenuItem value='volunteer' className={classes.menuItem}>
                      Volunteer
                    </MenuItem>
                    <MenuItem value='admin' className={classes.menuItem}>
                      Admin
                    </MenuItem>
                  </Select>
                }
                name='status'
                control={control}
              />
            </CardContent>
            <CardActions className={classes.cardActions} disableSpacing>
              <Button
                onClick={() => setRemoveDialogOpen(true)}
                variant='outlined'
                disabled={loading}
                fullWidth
              >
                Remove volunteer
              </Button>
              <Button
                className={classes.saveButton}
                variant='contained'
                type='submit'
                disabled={loading}
                endIcon={<SaveIcon />}
                fullWidth
              >
                Save changes
              </Button>
            </CardActions>
          </form>
        </Card>
      </Dialog>
    </>
  )
}

export default VolunteerInfoDialog
