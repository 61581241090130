import React, { useState } from 'react'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { TableCell, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ListItemButton from '../../../components/ListItemButton'
import VolunteerInfoDialog from './VolunteerInfoDialog'
import * as moment from 'moment'
import { thumbnailUrl } from '../../../utils/thumbnails'
import PlaceholderAvatar from '../../../static/placeholder-avatar.png'

const styles = () => ({
  tableRow: {
    borderRadius: '10px',
    cursor: 'pointer',
  },
  tableCell: {
    border: 0,
    padding: '10px 16px',
  },
  firstCell: {
    borderRadius: '10px 0 0 10px',
  },
  lastCell: {
    borderRadius: '0 10px 10px 0',
  },
  tableIcon: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  listButton: {
    minWidth: '92px',
  },
  label: {
    justifyContent: 'center',
  },
  mr3: {
    marginRight: '24px',
  },
})

const Volunteer = ({ userId, acceptedAt, volunteerStatus }) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)

  // Attach volunteers listener
  useFirestoreConnect([
    {
      collection: 'publicUserProfiles',
      doc: userId,
    },
  ])

  const user = useSelector((state) => state.firestore.data.publicUserProfiles?.[userId])

  const formattedDate = moment.unix(acceptedAt).format('D MMM YYYY')

  const avatarUrl = user?.avatarUrl ? thumbnailUrl(user?.avatarUrl, 200) : PlaceholderAvatar

  return (
    <>
      <VolunteerInfoDialog
        open={infoDialogOpen}
        handleClose={() => setInfoDialogOpen(false)}
        userId={userId}
        user={user}
        date={formattedDate}
      />
      <TableRow onClick={() => setInfoDialogOpen(true)} className={classes.tableRow} hover>
        <TableCell
          className={`${classes.tableCell} ${classes.firstCell}`}
          component='th'
          scope='row'
        >
          <img className={classes.avatar} src={avatarUrl} alt='' />
        </TableCell>
        <TableCell className={classes.tableCell} component='th' scope='row'>
          {user?.name}
        </TableCell>
        <TableCell className={classes.tableCell} component='th' scope='row'>
          {volunteerStatus}
        </TableCell>
        <TableCell className={classes.tableCell} align='right' component='th' scope='row'>
          {formattedDate}
        </TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.lastCell}`}
          align='right'
          component='th'
          scope='row'
        >
          <ListItemButton label='Info' />
        </TableCell>
      </TableRow>
    </>
  )
}

export default Volunteer
